import React from "react";
// import { Container } from "react-bootstrap";
import Layout from "../../layout/Layout";
import img from '../../assets/images/familyMembers.png';
import { Link } from "react-router-dom";

const AboutUs = (props: any) => {
  let userData                            = localStorage.getItem("profileData");
  return (
    <Layout>
      <div className="background-img">
       <div className="container-width mt-3 mb-3">
        <div className="row ">
          <div className="col-xl-6 text-center">
            {/* desktop only */}
            <img className="mt-85 mb-2 d-none d-xl-block mx-auto " src={img} alt="about us" />
          </div>
          <div className="aboutUsMainCOntent col-xl-6">
              <h2 className="heading-title text-center text-xl-start"> About Us </h2>
               {/* ipad/mobile only */}
              <img className="img-fluid my-3 mx-auto rounded-circle  d-block d-xl-none" src={img} alt="about us" />
              <div className=" text-white">
                <div className="bg-Main-Content mb-5">
                  <h4><span className="theme-color">The Story Behind BeyondBox</span></h4>
                  <p className="mb-lg-4">BeyondBox was born out of love, loss, and a deep desire to bridge the gaps that death leaves behind. Our journey began during a profoundly painful chapter in our lives. One that my wife and I will never forget. Over a span of three years, we experienced the unimaginable: the loss of both sets of our beloved parents. The grief was overwhelming, but what struck us most deeply was the silence that followed, the untold stories, the unsaid words, and the wisdom and love that we would never hear again. In our time of mourning, we realized something powerful: the most meaningful connections in life are not bound by time. Love, guidance, and cherished memories have the potential to transcend the boundaries of life and death, if only there were a way to preserve them. This realization sparked the idea for BeyondBox. A digital platform designed to ensure that the impact of a life well-lived is never lost but instead cherished and carried forward for generations.</p>
                </div>
                <div className="bg-Main-Content mb-5">
                  <h4><span className="theme-color">Our Purpose</span></h4>
                  <p className="mb-lg-4">At BeyondBox, we believe that intentional living and the creation of a lasting legacy are deeply intertwined. Life is precious, and each day is an opportunity to live with purpose, to share love, and to inspire those around us. Our mission is to provide an innovative platform that empowers people from all walks of life to document their dreams, share their wisdom, and capture their most heartfelt messages, transforming fleeting moments into timeless treasures. But BeyondBox is more than a tool for legacy preservation; it’s a meaningful reminder to make the most of the time we have with the people who matter most. From our own experience of profound loss, and the shared experiences of billions of families throughout time, we’ve learned the importance of prioritizing what truly matters: creating joyful memories and cherishing every moment with our loved ones. BeyondBox encourages you to seize the time you have, to celebrate life, and to leave a legacy filled with love and unforgettable memories.</p>
                </div>
                <div className="bg-Main-Content mb-5">
                  <h4><span className="theme-color">What We Believe</span></h4>
                  <p className="mb-lg-4">We believe that everyone’s life has an impact and that the stories we leave behind can be powerful sources of comfort, guidance, and inspiration. Whether it’s a heartfelt message to your children, a video sharing your life lessons, or a Vision Box filled with your dreams and goals, BeyondBox is a space where your voice can live on and continue to make a difference. Our platform is designed to be a secure, compassionate space for reflection, celebration, and meaningful connection. We know from personal experience that life is unpredictable, but what remains within our control is the legacy we choose to create. Our commitment is to help you leave an indelible mark. A mark that reflects your love, your values, and your most cherished moments.</p>
                </div>
                <div className="bg-Main-Content">
                  <h4><span className="theme-color">Join Us on This Journey</span></h4>
                  <p className="mb-lg-4">BeyondBox is more than a service; it’s a movement to live with purpose, create beautiful memories, and embrace the gift of life. We invite you to join our community of people who are documenting their dreams, celebrating their experiences, and ensuring that their stories live on. From our hearts to yours, we look forward to welcoming you to the BeyondBox family.</p>
                  <p className="mb-0"><span className="theme-color">Kapil & Advitha</span></p>
                </div>
              </div>
             {!userData && <div className="text-center">
                <Link to="/signup" className="button-secondary mt-3 mb-2 "><span> Join BeyondBox</span></Link>
          </div>}
          </div>
        </div>
      </div>
      </div>
    </Layout>
  );
};

export default AboutUs;