import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import Layout from "../../layout/Layout";

const FAQ = (props: any) => {
  return (
    <Layout>
      <div className={localStorage.length ? "" : "charity_publicBox"}>
        <Container>
          <div className="text-light">
            <div className="faqMainContent">
              <div className="heading"><h2>Frequently Asked Questions</h2></div>
              <p>BeyondBox is more than an app, it’s a tool for intentional living and meaningful legacy creation. Whether you’re capturing life’s most precious moments, planning for the future, or sharing heartfelt messages, BeyondBox empowers you to leave a profound and lasting impact on the people who matter most. Below, we've answered some of the most asked questions from our users. If you need further assistance or have additional questions, please don't hesitate to contact us through our Contact Us page.</p>
              <p>
                <h4 className="text-warning">Why BeyondBox?</h4>
                BeyondBox is designed to help you live fully and leave beautifully. It’s a space to preserve your love, wisdom, and memories while empowering you to live with purpose every day. From setting life goals to offering comfort and guidance in the wake of your absence, BeyondBox is your companion for crafting a legacy that will resonate for generations. Each box you create will hold immeasurable meaning and value to the recipients who will be fortunate enough to receive one.
              </p>
              <p>
              <h4 className="text-warning">Who can benefit from BeyondBox?</h4>
              BeyondBox is for anyone who:
              <ul style={{listStyleType:"disc"}}>
                    <li>Wants to preserve their life’s story and values for loved ones.</li>
                    <li>Aims to leave a profound impact on their family and friends after their passing.</li>
                    <li>Values intentional living and goal setting through tools like the Vision Box.</li>
                    <li>Seeks a secure and trusted space to store and share meaningful memories and messages.</li>
                </ul>
              </p>
              <p>
              <h4 className="text-warning">How does BeyondBox handle grief support?</h4>
              BeyondBox serves as a selfless and powerful remedy to grief by delivering heartfelt messages, wisdom, and memories to your loved ones after you pass away. These boxes provide comfort and guidance during their time of loss, helping them process their emotions with the enduring presence of your love and support.
              </p>
              <p>
              <h4 className="text-warning">How can BeyondBox help me plan intentionally?</h4>
              The Vision Box encourages users to reflect on their goals, aspirations, and values. It’s a space to document your dreams, track milestones, and live with purpose. This feature not only helps you plan your life intentionally but also leaves an inspiring blueprint for future generations.
              </p>
              <p>
              <h4 className="text-warning">Does BeyondBox have a Free Plan?</h4>
              Yes, it does. You can sign up for the BeyondBox Free Plan at no cost. The Free Plan allows you to create one BeyondBox with one recipient and includes 50MB of storage for all media types, such as text messages, photos, videos, audio messages, and documents. To unlock the premium features of BeyondBox, you have the option to upgrade to the Premium Plan for a minimal monthly fee.
              </p>
              <p>
              <h4 className="text-warning">What are the features of the Premium Plan?</h4>
              With a BeyondBox Premium Plan, you can enjoy all the best features BeyondBox has to offer.  
                <ul style={{listStyleType:"disc"}}>
                    <li>You can create and receive an unlimited number of boxes, record or upload video and audio, capture images, and add files and text messages.</li>
                    <li>You also get total plan storage of 2GB, and you can add up to five recipients per box.</li>
                    <li>You can create any type of box, providing a guided approach to creating meaningful and impactful content for your loved ones.</li>
                    <li>You get to keep your received boxes forever and can downgrade to the Free Plan at any time.</li>
                    <li>A portion of your subscription fee goes toward the BeyondBox Foundation, which helps existing charities and people in need.</li>
                </ul>
              </p>
              <p>
              <h4 className="text-warning">What are the different types of boxes that I can create with a Premium subscription?</h4>
              BeyondBox offers several types of boxes, each designed to guide you on creating impactful content for your loved ones:
              </p>
              <p>
              <h4 className="text-warning">BeyondBox</h4>
              Your ultimate legacy box where you can leave final goodbyes, heartfelt messages, and precious memories for your loved ones. Scheduled for release upon your passing.
              </p>
              <p>
              <h4 className="text-warning">Info-Box</h4>
              A practical solution for passing on essential information without storing sensitive data. Include contact details for financial advisors, lawyers, estate administrators, the location of your will, and other non-sensitive information to guide your loved ones.
              </p>
              <p>
              <h4 className="text-warning">Family-Box</h4>
              Document your family's history, heritage, and cherished moments. This box is designed to celebrate your family's unique story and can be shared with future generations.
              </p>
              <p>
                <h4 className="text-warning">Public-Box</h4>
                Leave a final message for the world. This box will be displayed on our Box Records page and posted on our social accounts to honour and celebrate your life.
              </p>
              <p>
                <h4 className="text-warning">Vision-Box</h4>
                A space for your life's dreams and aspirations. Document your goals, hopes, and dreams, and use it as a tool for reflection and motivation. The Vision Box can also be shared with loved ones after your passing to leave an inspiring legacy.
              </p>
              <p>
                <h4 className="text-warning">Tell me more about the BeyondBox Foundation?</h4>
                The BeyondBox Foundation was created as the philanthropic arm of the BeyondBox platform. We have pledged a third of all net revenue generated from the BeyondBox platform toward our Foundation. The primary purpose of the foundation is to provide financial assistance to existing charities in support of the vital humanitarian work they do.
              </p>
              <p>
                <h4 className="text-warning">Are the boxes I create encrypted and secure?</h4>
                Boxes are end-to-end encrypted and only you and your intended recipients will be able to view what you add to your box. The security of your content is of utmost importance to us, and we have implemented various security measures using AWS, SSL, and 2FA to ensure that your boxes are secure. AWS provides a secure cloud infrastructure that protects your data from unauthorized access. SSL encrypts the data in transit between your device and our servers. 2FA requires two forms of identification to access your account, such as a password and a verification code sent to your phone and registered email.
              </p>
              <p>
                <h4 className="text-warning">What is the Safe Space Philosophy?</h4>
                Our Safe Space Philosophy is a unique approach to data security designed to build trust in our brand and deter potential cyber threats. By promoting the storage of non-sensitive information, we create a sanctuary where users can confidently create, preserve, and share their legacies without fear. We encourage you to avoid storing sensitive content such as:
                <ul style={{listStyleType:"disc"}}>
                    <li>Financial information such as account numbers</li>
                    <li>Confidential company or business information</li>
                    <li>Identity, Social Security, or passport documents</li>
                    <li>Passwords or security keys</li>
                    <li>Documents containing sensitive information.</li>
                </ul>
                This philosophy, combined with our robust security measures, ensures that BeyondBox remains a safe and trusted platform for preserving your cherished memories.
              </p>
              <p>
                <h4 className="text-warning">Is there a BeyondBox mobile app?</h4>
                Yes, there is. BeyondBox is only accessible via the mobile app, which you can download from the Apple App Store or Google Play. The BeyondBox app makes it easy for you to access and create your timeless treasure boxes at a time and place of your choosing, discretely and conveniently. You can upload or record content on your mobile device, such as photos, videos, audio messages, or text notes, and add them to your boxes. You can also view the boxes that you have received from others and manage your account settings. The BeyondBox app gives you the freedom and flexibility to express yourself and share your legacy with your loved ones.
              </p>
              <p>
              <h4 className="text-warning">How do I create a box and add recipients?</h4>
              Creating a box and adding recipients is easy. Just follow these steps:
              <ol>
                    <li>Sign in to your account via the BeyondBox mobile app.</li>
                    <li>From your "My Boxes" page, click the "Add Box" button to open the Create Box page.</li>
                    <li>From the Create Box page, you can add a box cover or choose from the preset covers as well as give your box a name and description.</li>
                    <li>You can then add one or multiple recipients (up to 5 per box on the Premium Plan).</li>
                    <li>You can add new recipients or select a recipient from "My People" if they have already been added in a previous box.</li>
                    <li>Click the "Save" button, and your box will now be ready to be filled with your treasured content.</li>
                </ol>
              </p>
              <p>
              <h4 className="text-warning">What information will I need to add a recipient?</h4>
              You will need to enter the recipient's:
                <ul style={{listStyleType:"disc"}}>
                  <li>Name</li>
                  <li>Surname</li>
                  <li>Email Address.</li>
                  <li>Date of Birth</li>
                  <li>Relation to you</li>
                </ul>
              </p>
              <p>
              <h4 className="text-warning">Can I leave a box to a person who is below the age of 18?</h4>
                Yes, you may create a box for a child under the age of 18. In this instance, you will use the email address of the child's parent or guardian. The parent/guardian will be responsible for sharing the box with the intended recipient.
              </p>
              <p>
              <h4 className="text-warning">How will the BeyondBox team be notified of my passing?</h4>
                When you create your BeyondBox profile, you will be asked to nominate a Trustee or Next of Kin. This person must be notified that you have a BeyondBox account and instructed to advise us of your passing and furnish us with your valid Notice of Death issued by your country's government for us to activate the release of your created boxes.
              </p>
              <p>
              <h4 className="text-warning">When will the recipient be notified that I have left them a box?</h4>
                Your recipient will not know about the box until after you have passed away. We will only send them a notification and instructions to access the box when we receive and verify your official Notice of Death from your Trustee or Next of Kin.
              </p>
              <p>
              <h4 className="text-warning">Does a recipient need to sign up for a paid plan to receive the contents of a box?</h4>
                No, they do not. A recipient can sign up for the Free Plan, which is completely free, to view the boxes that you have left for them. Received boxes will be viewable for a period of 12 months with the BeyondBox Free Plan.
              </p>
              <p>
              <h4 className="text-warning">Can I schedule the release of content?</h4>
                Yes, you can. Each piece of content that you add to a box can be scheduled for release for up to 10 years after your death.
              </p>
              <p>
              <h4 className="text-warning">Can I make changes to a box after it has been created?</h4>
                Yes, you can edit a created box at any time. Simply click on "Edit Box," and you will be able to update the box cover, name, description, and recipients.
              </p>
              <p>
              <h4 className="text-warning">Can I delete items in a box?</h4>
                Yes, you can. You can delete individual pieces of content or entire boxes. Deleted items will remain in your Recycle Bin for a period of 30 days. You can visit your Recycle Bin to restore content within this period.
              </p>
              <p>
              <h4 className="text-warning">Why does BeyondBox have a Suicide Exclusion?</h4>
                All users of BeyondBox must accept a Suicide Exclusion when signing up for the platform. This Exclusion states that if your death is due to suicide, we will not release your boxes. This is because we do not want the platform to be used as a tool to compile suicide notes and inadvertently discourage the user from seeking the help they need. We care deeply about the lives of all our users and want to encourage them to plan toward the inevitability of their passing while always remembering the preciousness of the gift of life. 
              </p>
              <h4 className="text-warning">How can you contact us?</h4>
              <p>
                <strong>Email: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> info@beyondbox.org<br />
                <strong>Web: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <Link to="/">www.beyondbox.org</Link><br />
                <strong>WhatsApp: &nbsp;</strong> (+27)78 479 0054
              </p>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default FAQ;