import React, { useEffect, useState } from "react";
// import { Container } from "react-bootstrap";
import Layout from "../../layout/Layout";
import img from '../../assets/images/blogImage.png';
import blogimg1 from '../../assets/images/blog-img1.webp';
import { Link } from 'react-router-dom';

import { connect } from "react-redux";
import { getBlogList } from "../../redux/actions/admin/adminBlogAction";
import { BASE_URLS } from "../../redux/constant";
const Blog = (props: any) => {
    interface BlogItem {
        heading:string;
        id:number;
        order: number;
        image: string;
        description: string;
        category: string;
    }
    const [blogData,setBlogData]=useState([])
    useEffect(()=>{
        props.getBlogList();
    },[])
    useEffect(() => {
        if (props.blogListData) {
            setBlogData(props.blogListData.blogs);
        }
      }, [props.blogListData]);
    
  
    const sortedBlogData = (blogData as BlogItem[]).sort((a, b) => a.order - b.order);
    const postsPerPage =props.blogListData&&props.blogListData.postPerPage?props.blogListData.postPerPage:10;
    const [currentPage, setCurrentPage] = useState(1);
  
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedBlogData.slice(indexOfFirstPost, indexOfLastPost);
  return (
    <Layout>
        <div className="blog-container">
            <div className="intro">
                <div className="half-section">
                <div className="center-content">
                    <h2 className="heading-title text-center ">"Live Before You Leave"<br></br><span style={{ textAlign: 'center'}}>The<span className="theme-color">Beyond</span>Box<b>Blog</b>
</span> </h2>
                    {/* mobile/ipad only */}
                    <img className="img-fluid my-3 mx-auto rounded-circle d-block d-xl-none" src={img} alt="" />
                    <div className="">
                        <div className="bg-Main-Content text-white mb-3">
                            <p>
                            "Live Before You Leave" is BeyondBox's dedicated space for connecting, learning, and inspiring each other as we navigate the paths of our lives and the legacies we wish to forge. This blog is your comprehensive guide to making the most of your days, with practical insights and inspiration across several key areas:
                            </p>
                        </div>
                        <div className="bg-Main-Content text-white mb-3">
                         
<h4 className="theme-color">Educating Users on BeyondBox</h4>
<p>Discover the full potential of BeyondBox, from setting up your digital legacy to utilizing advanced features that enhance your experience. Learn how our platform can help you craft meaningful messages and secure them for future generations.</p>
</div>

<div className="bg-Main-Content text-white mb-3">
<h4 className="theme-color">Legacy Crafting and Preservation</h4>
<p>Delve into the art of legacy planning with tips on curating your life stories, advice, and wisdom. Explore how to create impactful and enduring messages that resonate with your loved ones and ensure your legacy is preserved as you envision.</p>
</div>

<div className="bg-Main-Content text-white mb-3">
<h4 className="theme-color">Physical and Mental Wellness</h4>
<p>Understand the importance of well-being in crafting a life worth remembering. Our articles cover wellness strategies, stress management, and healthy habits that support a joyful and fulfilling life.</p>
</div>

<div className="bg-Main-Content text-white mb-3">
<h4 className="theme-color">Financial Wellness</h4>
<p>Navigate the complexities of financial planning with guidance on saving, investing, and estate planning to secure your and your family's future. Learn how financial wellness contributes to a stress-free legacy.</p>
</div>

<div className="bg-Main-Content text-white mb-3">
<h4 className="theme-color">Entertainment</h4>
<p>Lighten your days with our entertainment suggestions, including must-see movies, books, and activities that can be enjoyed alone or with loved ones. Perfect for those looking to add some joy to their life or complete their bucket list.</p>
</div>

<div className="bg-Main-Content text-white mb-3">
<h4 className="theme-color"> Bucket List Adventures</h4>
<p>Get inspired to live life to the fullest with ideas for bucket list adventures and experiences. From travel tips to local hidden gems, discover new ways to make unforgettable memories.</p>
</div>

<div className="bg-Main-Content text-white mb-3">
                            <p>
                              
"Live Before You Leave" aims to inspire you to appreciate each moment, educate you on the practicalities of legacy creation, and encourage a life filled with joy and purpose. Join us on this journey to make every day meaningful and every message a treasure. 
                            </p>
                        </div>
                    </div>
                </div>
                </div>
                {/* desktop only */}
                <div className="img-Cover d-none d-xl-block">
                    <img className="img-fluid" src={img} alt="" />
                </div>
            </div>

            <div className="bg-img">
                <div className="container-width-80 py-container">
                    <div>
                        {currentPosts.map(blog => (
                            <div key={blog.id} className="bg-Main-Content text-white mb-5">
                                <div className="mobile-card-box">
                                    <div className="row">
                                        <div className="col-lg-3 blog-img-cover">
                                            <img  src={`${BASE_URLS.BASE_URL}/user-box-files/resource?key=${blog.image}`} alt="" />
                                        </div>
                                        <div className="col-lg-9 pl-35 plx-0 mtx-15">
                                            <h3 className="theme-color" style={{wordWrap:'break-word'}}>{blog.heading}</h3>
                                            {/* <p  >{blog.description.length <= 300 ? blog.description : blog.description.slice(0, 300) + '...'}</p> */}
                                            <p style={{ wordWrap: 'break-word' }}>
                                                {blog.description.length <= 300 ? (
                                                    <span dangerouslySetInnerHTML={{ __html: blog.description}}></span>
                                                ) : (
                                                    <span dangerouslySetInnerHTML={{ __html: blog.description.slice(0, 300) + '...' }}></span>
                                                )}
                                            </p>
                                            {/* Assuming you want to link to individual blog posts */}
                                            
                                            <Link to={`/admin-blog-detail/${blog.id}`} className="view-post-link">
                                                View Post
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}


    <div>
        {blogData.length > postsPerPage && (
            <ul className="pagination justify-content-center">
                {/* Start button */}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button onClick={() => setCurrentPage(1)} className="page-link">&laquo;</button>
                </li>
                
                {/* Previous button */}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button onClick={() => setCurrentPage(currentPage - 1)} className="page-link">&lsaquo;</button>
                </li>
                
                {/* Pagination buttons */}
                {Array.from({ length: Math.min(3, Math.ceil(blogData.length / postsPerPage)) }, (_, i) => {
                    const pageNumber = currentPage - 1 + i;
                    if (pageNumber >= 1 && pageNumber <= Math.ceil(blogData.length / postsPerPage)) {
                        return (
                            <li key={i} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                                <button onClick={() => setCurrentPage(pageNumber)} className="page-link">{pageNumber}</button>
                            </li>
                        );
                    } else {
                        return null; // Render nothing for excess page numbers or negative page numbers
                    }
                })}
                
                {/* Next button */}
                <li className={`page-item ${currentPage === Math.ceil(blogData.length / postsPerPage) ? 'disabled' : ''}`}>
                    <button onClick={() => setCurrentPage(currentPage + 1)} className="page-link">&rsaquo;</button>
                </li>
                
                {/* End button */}
                <li className={`page-item ${currentPage === Math.ceil(blogData.length / postsPerPage) ? 'disabled' : ''}`}>
                    <button onClick={() => setCurrentPage(Math.ceil(blogData.length / postsPerPage))} className="page-link">&raquo;</button>
                </li>
            </ul>
        )}
    </div>



                    </div>
                </div>
            </div>
            
            {/* <div className="bg-img">
                <div className="container-width-80 py-container mb-5"> 
                    <h2 className="heading-title"> <span className="theme-color">Top 10 bucket list places to visit</span>  </h2>
        
                    <div className="bg-Main-Content text-white ">
                        <div className="mobile-card-box">
                            <p className="mb-md-5">
                                Meet Bebo, BeyondBox's In-House Blogger Bot and the newest member of our team! Crafted with
                                creativity and passion, Bebo is here to enrich your BeyondBox experience. Powered by the latest in AI
                                technology, Bebo brings a wealth of knowledge to our blog, offering our readers not just content, but an
                                experience. With access to extensive information and a unique ability to generate insightful, engaging
                                articles, Created to be your guide, muse, and friend, Bebo embodies the essence of.
                            </p>
                        </div>
                        <div className="mb-md-5 mobile-card-box">
                            <h5> <span className="theme-color">Heading in different colour</span> </h5>
                            <p>
                            BeyondBox's In-House Blogger Bot and the newest member of our team! Crafted with creativity and
                            passion, Bebo is here to enrich your BeyondBox experience. Powered by the latest in AI technology,
                            Bebo brings a wealth of knowledge to our blog, offering our readers not just content, but an experience.
                            With access to extensive information and a unique ability to generate insightful, engaging articles,
                            Created to be your guide, muse, and friend, Bebo embodies the essence of .
                            </p>
                        </div>
                        <div className="mb-md-5 mobile-card-box">
                            <h5> <span className="theme-color">Heading in different colour</span> </h5>
                            <p>
                            BeyondBox's In-House Blogger Bot and the newest member of our team! Crafted with creativity and
                            passion, Bebo is here to enrich your BeyondBox experience. Powered by the latest in AI technology,
                            Bebo brings a wealth of knowledge to our blog, offering our readers not just content, but an experience.
                            With access to extensive information and a unique ability to generate insightful, engaging articles,
                            Created to be your guide, muse, and friend, Bebo embodies the essence of .
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div> */}

            {/* <div className="bg-img">
                <div className="container-width-80 py-container">
                    <h2 className="theme-color">
                    Related Posts</h2>
                    <div className="bg-Main-Content text-white mb-5">
                        <div className="row">
                            <div className="col-md-4 col-lg-3 blog-img-cover">
                                <img src={blogimg1} alt="" />
                            </div>
                            <div className="col-md-8 col-lg-9 pl-50 plx-0 mtx-15">
                                <h3 className="theme-color">
                                    A guide to creating your first BeyondBox
                                </h3>
                                <p>
                                    Meet Bebo, BeyondBox's In-House Blogger Bot and the newest member of our team!
                                    Crafted with creativity and passion, Bebo is here to enrich your BeyondBox
                                    experience. Powered by the latest in AI technology, Bebo brings a wealth of
                                    knowledge to our blog, offering our readers not just content, but an experience. With
                                    access to extensive information and a unique ability to generate insightful, engaging
                                    articles, Created to be your guide, muse, and friend, Bebo embodies the essence of
                                </p>
                                <Link to="#" className="view-post-link">
                                    View Post
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="bg-Main-Content text-white mb-5">
                        <div className="row">
                            <div className="col-md-4 col-lg-3 blog-img-cover">
                                <img src={blogimg1} alt="" />
                            </div>
                            <div className="col-md-8 col-lg-9 pl-50 plx-0 mtx-15">
                                <h3 className="theme-color">
                                    A guide to creating your first BeyondBox
                                </h3>
                                <p>
                                    Meet Bebo, BeyondBox's In-House Blogger Bot and the newest member of our team!
                                    Crafted with creativity and passion, Bebo is here to enrich your BeyondBox
                                    experience. Powered by the latest in AI technology, Bebo brings a wealth of
                                    knowledge to our blog, offering our readers not just content, but an experience. With
                                    access to extensive information and a unique ability to generate insightful, engaging
                                    articles, Created to be your guide, muse, and friend, Bebo embodies the essence of
                                </p>
                                <Link to="#" className="view-post-link">
                                    View Post
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </Layout>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
    getBlogList: (data: any) => dispatch(getBlogList(data)),
  });
  const mapStateToProps = (state: any) => {
    return {
      blogListData: state.AdminBlogReducer && state.AdminBlogReducer.blogListData ? state.AdminBlogReducer.blogListData : null,
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(Blog);